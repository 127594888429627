import authorizeClient from './authorizeClient.graphql'
import createBooking from './createBooking.graphql'
import createCustomer from './createCustomer.graphql'
import deleteCustomer from './deleteCustomer.graphql'
import updateCustomer from './updateCustomer.graphql'
import updateReservation from './updateReservation.graphql'
import confirmReservation from './confirmReservation.graphql'
import cancelReservation from './cancelReservation.graphql'
import createProductReservation from './createProductReservation.graphql'
import addPassengerToBooking from './addPassengerToBooking.graphql'
import cancelPassenger from './cancelPassenger.graphql'
import uncancelPassenger from './uncancelPassenger.graphql'
import updatePassenger from './updatePassenger.graphql'
import updateBooking from './updateBooking.graphql'
import removeBookingCustomer from './removeBookingCustomer.graphql'
import addBookingNote from './addBookingNote.graphql'
import updateBookingNote from './updateBookingNote.graphql'
import removeBookingNote from './removeBookingNote.graphql'
import addPassengerNote from './addPassengerNote.graphql'
import updatePassengerNote from './updatePassengerNote.graphql'
import removePassengerNote from './removePassengerNote.graphql'
import deletePassengerFile from './deletePassengerFile.graphql'
import uploadPassengerFile from './uploadPassengerFile.graphql'
import updateBookingSummary from './updateBookingSummary.graphql'
import cancelUserInvite from './cancelUserInvite.graphql'
import createBookingTransaction from './createBookingTransaction.graphql'
import createPaymentAccount from './createBookingPaymentAccount.graphql'
import createBookingTransfer from './createBookingTransfer.graphql'
import addCustomerNote from './addCustomerNote.graphql'
import updateCustomerNote from './updateCustomerNote.graphql'
import removeCustomerNote from './removeCustomerNote.graphql'
import {
  modifyBookingTags,
  modifyPassengerTags,
  modifyCustomerTags,
  modifyProductTags,
  modifyReservationTags,
} from './modifyTags.graphql'
import archiveReservation from './archiveReservation.graphql'

const Mutations = {
  archiveReservation,
  authorizeClient,
  createBooking,
  createCustomer,
  deleteCustomer,
  updateCustomer,
  addCustomerNote,
  updateCustomerNote,
  removeCustomerNote,
  updateReservation,
  confirmReservation,
  cancelReservation,
  createProductReservation,
  addPassengerToBooking,
  cancelPassenger,
  uncancelPassenger,
  updatePassenger,
  updateBooking,
  removeBookingCustomer,
  addBookingNote,
  updateBookingNote,
  removeBookingNote,
  addPassengerNote,
  updatePassengerNote,
  removePassengerNote,
  deletePassengerFile,
  uploadPassengerFile,
  updateBookingSummary,
  cancelUserInvite,
  createBookingTransaction,
  createPaymentAccount,
  createBookingTransfer,
  modifyBookingTags,
  modifyPassengerTags,
  modifyCustomerTags,
  modifyProductTags,
  modifyReservationTags,
}

export default Mutations
