import { defineStore } from 'pinia'
import Mutations from '~/apollo/mutations'
// Types.
//
import { type Tag, type TagOperation, TagScope } from '~/apollo/types'
// GQL queries.
//
import getTagsQuery from '@/apollo/queries/listTags.graphql'
import createTagMutation from '@/apollo/mutations/createTag.graphql'
import updateTagMutation from '@/apollo/mutations/updateTagProperties.graphql'

type TagFilters = {
  name: string | null
  type: string | null
}

type TaggedResource = {
  id: string
  tags: string[]
}

type CurrentTags = {
  booking: TaggedResource[]
  passenger: TaggedResource[]
  reservation: TaggedResource[]
}

type TagStore = {
  tags: Tag[]
  currentTags: CurrentTags
  filters: TagFilters
  offset: number
  perPage: number
  pageCount: number | null
  currentPage: number
  totalCount: number
}

export const useTagStore = defineStore({
  id: 'tags',
  state: (): TagStore => ({
    tags: [],
    currentTags: {
      booking: [],
      passenger: [],
      reservation: [],
    },
    filters: {
      name: null,
      type: null,
    },
    offset: 0,
    perPage: 10,
    pageCount: null,
    currentPage: 1,
    totalCount: 0,
  }),
  actions: {
    // Gets all the tags on initial page load
    async getTags(organizationId: string, client: any) {
      const resp = await client
        .query({
          query: getTagsQuery,
          variables: {
            organizationId,
          },
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'network-only',
          cache: false,
        })
        .catch((e: any) => {
          console.log(e)
        })

      if (resp) {
        this.totalCount = resp.data.tags.list.totalCount
        return (this.tags = resp.data.tags.list.edges.map(
          ({ node }: any) => node,
        ))
      }
    },
    setTags(id: string, tags: string[], scope: TagScope) {
      if (!id || !scope) return

      const key: keyof CurrentTags = this.resolveKeyFromScope(scope)

      const index = this.currentTags[key].findIndex(
        (entity) => entity.id === id,
      )
      if (index === -1) {
        this.currentTags[key].push({
          id,
          tags,
        })
      }
    },
    clearCurrentTags() {
      this.currentTags = {
        booking: [],
        passenger: [],
        reservation: [],
      }
    },
    resolveKeyFromScope(scope: TagScope): keyof CurrentTags {
      let key: keyof CurrentTags | null = null
      switch (scope) {
        case TagScope.Bookings:
          key = 'booking'
          break
        case TagScope.Passengers:
          key = 'passenger'
          break
        case TagScope.ProductReservations:
          key = 'reservation'
          break
      }
      return key!
    },
    resolveMutation(scope: TagScope) {
      let mutation
      switch (scope) {
        case TagScope.Bookings:
          mutation = Mutations.modifyBookingTags
          break
        case TagScope.Passengers:
          mutation = Mutations.modifyPassengerTags
          break
        case TagScope.Customers:
          mutation = Mutations.modifyCustomerTags
          break
        case TagScope.Products:
          mutation = Mutations.modifyProductTags
          break
        case TagScope.ProductReservations:
          mutation = Mutations.modifyReservationTags
          break
      }
      return mutation
    },
    // Adds/Removes a tag on a resource e.g. add/remove a tag on a booking
    async modifyTags(
      id: string,
      operation: TagOperation,
      tags: Array<Tag['tag']>,
      scope: TagScope,
      client: any,
    ) {
      const mutation = this.resolveMutation(scope)
      const resp = await client
        .mutate({
          mutation,
          variables: {
            id,
            operation,
            tags,
          },
        })
        .catch((e: any) => {
          console.log(e)
          return false
        })

      if (resp) {
        const key = this.resolveKeyFromScope(scope)
        const index = this.currentTags[key].findIndex(
          (entity) => entity.id === id,
        )

        if (index !== -1) {
          return (this.currentTags[key][index] = {
            id,
            tags: resp.data[key].tags.update,
          })
        } else {
          this.currentTags[key].push({
            id,
            tags: resp.data[key].tags.update,
          })
        }
      }
    },
    // Suggests tags based on the value that is typed - Returns tags that match the search value & scope and aren't already applied
    suggestTags(scope: TagScope, id: string, searchedValue?: string) {
      const key: keyof CurrentTags = this.resolveKeyFromScope(scope)
      const index = this.currentTags[key].findIndex(
        (entity) => entity.id === id,
      )

      // get list of tags currently set on the resource to filter against
      let currentTagsSet: Set<string>
      if (this.currentTags[key][index] && this.currentTags[key][index].tags) {
        currentTagsSet = new Set(
          this.currentTags[key][index].tags.map((tag) => tag.toLowerCase()),
        )
      } else {
        currentTagsSet = new Set([])
      }

      // If no search value return filtered list
      if (!searchedValue || !searchedValue.length) {
        const filteredTags = this.tags.filter((tag) => {
          const tagLower = tag.tag.toLowerCase()
          return !currentTagsSet.has(tagLower) && tag.scope === scope
        })
        return filteredTags.map((t) => t.tag)
      }

      const searchedValueLower = searchedValue.toLowerCase()
      // Returns filtered tags that match the search value
      const filteredTags = this.tags.filter((tag) => {
        const tagLower = tag.tag.toLowerCase()
        return (
          tagLower.includes(searchedValueLower) &&
          !currentTagsSet.has(tagLower) &&
          tag.scope === scope
        )
      })

      return filteredTags.map((t) => t.tag)
    },
    // Updates tag properties e.g. colour, description
    async updateTagProperties(
      organizationId: string,
      client: any,
      data: any,
      action: string,
    ) {
      const mutation =
        action === 'create' ? createTagMutation : updateTagMutation
      let error
      const resp = await client
        .mutate({
          mutation,
          variables: {
            organizationId,
            tag: data.tag,
            color: data.color || '',
            description: data.description || '',
            scope: data.scope,
          },
        })
        .catch((e: any) => {
          console.log(e)
          error = e.message
        })

      if (error) {
        return { error }
      } else {
        return await this.getTags(organizationId, client)
      }
    },
    resetState() {
      this.currentTags = {
        booking: [],
        passenger: [],
        reservation: [],
      }
      this.tags = []
      this.filters = {
        name: null,
        type: null,
      }
      this.offset = 0
      this.perPage = 10
      this.pageCount = null
      this.currentPage = 1
    },
  },
  getters: {
    // Gets all the data about a tag (e.g. colour) from the tag name (used on pages such as bookings where the query only returns an array of tag names)
    getTagMetadata: (state) => (scope: TagScope, id: string) => {
      const key: keyof CurrentTags = state.resolveKeyFromScope(scope)

      let filteredTags: Tag[] = []

      const index = state.currentTags[key].findIndex(
        (entity) => entity.id === id,
      )

      if (index === -1) return filteredTags

      if (
        state.currentTags[key][index].tags &&
        state.currentTags[key][index].tags.length > 0
      ) {
        filteredTags = state.tags.filter(
          (item: Tag) =>
            state.currentTags[key][index].tags.includes(item.tag) &&
            item.scope === scope,
        )
      }
      return filteredTags
    },
    // Used on the /settings/tags page to filter the tags by name/scope
    getFilteredTags: (state) => {
      let filteredTags: Tag[] = []
      if (state.filters.name === null && state.filters.type === null) {
        filteredTags = state.tags
      }
      if (state.filters.name !== null && state.filters.type === null) {
        filteredTags = state.tags.filter((tag) => {
          return tag.tag
            .toLowerCase()
            .includes(state.filters.name!.toLowerCase())
        })
      }
      if (state.filters.type !== null && state.filters.name === null) {
        filteredTags = state.tags.filter((tag) => {
          return tag.scope === state.filters.type
        })
      }
      if (state.filters.type !== null && state.filters.name !== null) {
        filteredTags = state.tags.filter((tag) => {
          return (
            tag.scope === state.filters.type &&
            tag.tag.toLowerCase().includes(state.filters.name!.toLowerCase())
          )
        })
      }
      state.pageCount = Math.ceil(filteredTags.length / state.perPage)
      return filteredTags
    },
  },
  persist: {
    storage: localStorage,
    paths: ['tags', 'currentTags'],
  },
})
